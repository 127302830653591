import React from "react";
import {
  Navbar,
  Nav,
  NavDropdown,
  Form,
  FormControl,
  Button,
  Container,
  InputGroup,
  Dropdown,
} from "react-bootstrap";
import { Link } from "react-router-dom";
import "bootstrap-icons/font/bootstrap-icons.css";
import NewLogo from "../images/new-logo.png";

const CompanyBanner = () => {
  return (
    <>
      <Navbar expand="lg" className="custom-navbar main-header">
        <Container fluid>
          <Navbar.Brand href="#home" className="navbar-brand-custom">
            <img
              src={NewLogo}
              alt="Logo"
              width="80"
              height="50"
              className="d-inline-block align-top"
            />{" "}
          </Navbar.Brand>

          <Navbar.Toggle aria-controls="navbar-content" />

          <Navbar.Collapse
            id="navbar-content"
            className="justify-content-between"
          >
            <Nav className="d-flex align-items-center ml-5">
              <Dropdown className="nav-item-custom me-3">
                <Dropdown.Toggle
                  as="div"
                  bsPrefix="d-flex align-items-center"
                  className="dropdown-toggle-custom"
                >
                  <button
                    className="d-flex align-items-center explore-button"
                  >
                    Explore
                    <i className="bi bi-caret-down-fill ms-2"></i>
                  </button>
                </Dropdown.Toggle>

                <Dropdown.Menu>
                  <Dropdown.Item href="#action/3.1">Content Library</Dropdown.Item>
                  <Dropdown.Item href="#action/3.2">Courses</Dropdown.Item>
                  <Dropdown.Item href="#action/3.3">Challenges</Dropdown.Item>
                  <Dropdown.Item href="#action/3.4">Events</Dropdown.Item>
                  <Dropdown.Item href="#action/3.5">Social Wall</Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>

              <Form className="d-flex search-form w-auto ml-3">
                <InputGroup>
                  <FormControl
                    type="search"
                    placeholder="What do you want to learn?"
                    aria-label="Search"
                    style={{ width: '20em', backgroundColor: 'white', height:'2.8em' }}
                  />
                  <InputGroup.Text className="search-icon-nav">
                    <i className="bi bi-search"></i>
                  </InputGroup.Text>
                </InputGroup>
              </Form>
            </Nav>

            <Nav className="d-flex align-items-center">
              <NavDropdown
                title={<span className="text-white">Online Degrees</span>}
                id="degrees-dropdown"
                className="nav-item-custom me-3 text-white"
              >
                <NavDropdown.Item href="#action/4.1">BSCS</NavDropdown.Item>
                <NavDropdown.Item href="#action/4.2">BSIT</NavDropdown.Item>
                <NavDropdown.Item href="#action/4.3">MBA</NavDropdown.Item>
                <NavDropdown.Item href="#action/4.4">CA</NavDropdown.Item>
                <NavDropdown.Item href="#action/4.5">BCA</NavDropdown.Item>
              </NavDropdown>

              <Nav.Link href="/login">
                <button className="login-button">Login</button>
              </Nav.Link>
              <Nav.Link>
                <button className="join-button">Join For Free</button>
              </Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>

      <div className="notification-section">
        <p>
          Email messaging is not available!{" "}
          <Link to="/settings/company/" className="update-link">
            Please update company details.
          </Link>
        </p>
      </div>
    </>
  );
};

export default CompanyBanner;
