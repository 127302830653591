import React, { createContext, useContext, useEffect, useState } from "react";
import useSocket from "../../hooks/useSockets";
import axios from 'axios';
import { commonUtil } from "../../helper/CommonUtils";
const NotificationContext = createContext();

export const useNotification = () => {
    return useContext(NotificationContext);
};

export const NotificationProvider = ({ children }) => {
    const BASEURL = commonUtil.BASEURL();
    const [notifications, setNotifications] = useState([]);
    const { socket, events } = useSocket();

    useEffect(() => {
        if (socket) {
            socket.emit(events.NEW_USER, localStorage.getItem("user_id"), "Partner");

            socket.on(events.TAKE_NOTIFICATIONS, (data) => {
                if(Array.isArray(data)) {
                    console.log(data,"notification data")
                    data.forEach(notification => {
                            addNotification(notification);
                    });
                }
                else {
                    console.warn("Received invalid data from socket server. Expected an array of notifications")
                }
            });
        }

        return () => {
            if (socket) {
                socket.off(events.TAKE_NOTIFICATION);
            }
        };
    }, [socket]);

    const addNotification = (notification) => {
        if(Array.isArray(notification)) {
            console.warn("Please pass a single notification object to addNotification() function")
            return;
        }
        const existingNotification = notifications.find((n) => n._id === notification._id);

        if (!existingNotification) {
            setNotifications((oldState) => [notification, ...oldState]);
            
        }
    };

    const removeNotification = async (id) => {
        
        const removedNotification = notifications.find((notification) => notification._id === id);
        
         if (removedNotification) {
            
            try {
                await axios
                .put(BASEURL + "notification/updateNotification/" + removedNotification._id)
                .then((res) => {

                    console.log("Response:", res);

                    setNotifications((oldState) =>
                        oldState.map((notification) =>
                            notification._id === id ? { ...notification, status: 'Read' } : notification
                        )
                    );

                });
                
            } catch (error) {
                console.error(error);
            }
        }
    };

    const deleteNotification = async (idsToRemove,isAllSelected) => {
        const userId = localStorage.getItem('user_id');
        if (idsToRemove) {
             
            const payload={
                ids:idsToRemove,
                isAllSelected:isAllSelected,
                userName:'partner',
                user:userId,
            }
            try {

                await axios
                .delete(BASEURL + "notification/deleteNotification",{data:payload})
                .then((res) => {

                    console.log("Response:", res);

                    setNotifications((oldState) =>
                        oldState.filter((notification) =>
                            !idsToRemove.includes(notification._id)
                        )
                    );
                });
                
            } catch (error) {
                console.error(error);
            }
        }
    }
    

    return (
        <NotificationContext.Provider
            value={{ notifications, addNotification, removeNotification ,deleteNotification}}
        >
            {children}
        </NotificationContext.Provider>
    );
};
